import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient'; // Import Supabase configuration
import { TonConnectButton, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import { useUser } from '../context/userContext';
import { IoCheckmarkCircleSharp, IoClose } from 'react-icons/io5';

const TonTask = () => {
  const { id: userId, balance, setBalance, tonTasks, setTaskPoints, setTonTasks, tonTransactions, setTonTransactions } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [openUpgrade, setOpenUpgrade] = useState(false);
  const [showCongratsModal, setShowCongratsModal] = useState(false);
  const [congratsMessage, setCongratsMessage] = useState("");
  const [buttonText, setButtonText] = useState("Make Purchase");
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("");
  const wallets = useTonWallet();
  const [tonConnectUI] = useTonConnectUI();
  const [congrats, setCongrats] = useState(false);
  const bonusAward = 5; // Reward of 5 USDT
  const price = 0.4; // Transaction price in TON

  // Fetch data from the database when component mounts
  useEffect(() => {
    const fetchTonTaskStatus = async () => {
      try {
        const { data, error } = await supabase
          .from('telegramUsers')
          .select('ton_tasks')
          .eq('userId', userId)
          .single();

        if (error) throw error;

        if (data && data.ton_tasks) {
          setTonTasks(data.ton_tasks);
        }
      } catch (error) {
        console.error('Error fetching TON task status:', error);
      }
    };

    if (userId) {
      fetchTonTaskStatus();
    }
  }, [userId, setTonTasks]);

  useEffect(() => {
    const initializeTonConnect = async () => {
      try {
        await tonConnectUI.connectionRestored;
        setIsLoading(false);
      } catch (err) {
        console.error('TonConnect initialization error:', err);
        setMessage(`TonConnect error: ${err.message}`);
        setMessageColor("red");
        setIsLoading(false);
      }
    };
    initializeTonConnect();
  }, [tonConnectUI]);

  const transaction = (cost) => ({
    validUntil: Math.floor(Date.now() / 1000) + 300,
    messages: [
      {
        address: "UQAZzylRpa9bX9oSodHpd4VdTfKYoQi4RdpDbZrwfd-t9ERG",
        amount: (cost * 1000000000).toString(), // Convert TON to miniTON (0.4 * 1,000,000,000)
      },
    ],
  });

  const handleClick = async () => {
    setButtonText("Processing...");
    setButtonDisabled(true);

    try {
      const response = await tonConnectUI.sendTransaction(transaction(price));
      console.log('Transaction sent successfully', response);

      const { error } = await supabase
        .from('telegramUsers')
        .update({
          balance: balance + bonusAward,
          ton_transactions: tonTransactions + 1,
          ton_tasks: true,
          task_points: balance + bonusAward,
        })
        .eq('userId', userId);

      if (error) throw error;

      setBalance(prevBalance => prevBalance + bonusAward);
      setTonTransactions(prevTransactions => prevTransactions + 1);
      setTonTasks(true);
      setTaskPoints(prevTaskPoints => prevTaskPoints + bonusAward);

      setCongratsMessage(
        <div className="w-full flex justify-center flex-col items-center space-y-3">
          <IoCheckmarkCircleSharp size={32} className="text-accent" />
          <p className="font-medium text-center">Congratulations!</p>
          <span className="font-medium text-[20px] text-[#ffffff] pt-2 pb-2 flex flex-col justify-center w-full text-center items-center space-x-1">
            <span className='flex items-center justify-center space-x-[2px] text-[18px]'>
              <img src='/dogs.webp' alt='reward' className='w-[15px]' />
              <span className="text-accent">+ 5 $USDT CLAIMED</span>
            </span>
            <span>Task Completed</span>
          </span>
          <p className="pb-6 text-[15px] w-full text-center">
            Perform more activities or buy more $USDT to stay ahead and claim listing giveaway bonuses! 😎
          </p>
        </div>
      );

      setShowCongratsModal(true);
      setOpenUpgrade(false);
      setCongrats(true);
      setTimeout(() => {
        setCongrats(false);
      }, 3000);

      setMessage("");
      setMessageColor("green");
    } catch (err) {
      console.error('Transaction error:', err);
      setMessage("Transaction failed or canceled, please try again later.");
      setMessageColor("red");
    } finally {
      setButtonText("Make Purchase");
      setButtonDisabled(false);
    }
  };

  const closeUpgrader = () => {
    setOpenUpgrade(false);
    setMessage("");
  };

  return (
    <>
      <div className="w-full rounded-[16px] py-3 flex items-center justify-between space-x-1">
        <div className='w-fit pr-2'>
          <div className='flex items-center justify-center bg-[#1f2023] h-[45px] w-[45px] rounded-full p-1'>
            <img alt="engy" src='/ton.png' className='w-[20px]' />
          </div>
        </div>
        <div className="flex h-full flex-1 flex-col justify-center relative">
          <div className='flex w-full flex-col justify-between h-full space-y-1'>
            <h1 className="text-[15px] font-medium">First Deposit Bonus</h1>
            <span className='flex text-secondary items-center w-fit text-[15px]'>
              <span>+{bonusAward} $USDT</span>
            </span>
          </div>
        </div>
        <div className='w-fit flex items-center justify-end flex-wrap text-[14px] relative'>
          {tonTasks ? (
            <IoCheckmarkCircleSharp size={28} className="text-accent" />
          ) : (
            <button
              onClick={() => setOpenUpgrade(true)}
              className="bg-[#1f2023] hover:bg-[#36373c] text-[#fff] w-fit py-[10px] rounded-[30px] px-5 font-semibold"
            >
              Start
            </button>
          )}
        </div>
      </div>

      {openUpgrade && (
        <div className="fixed flex bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#303030c4] flex-col justify-end items-center">
          <div className="w-full bg-divider shadowtop rounded-tl-[40px] rounded-tr-[40px] relative flex flex-col ease-in duration-300 justify-center">
            <div className="w-full flex taskbg rounded-tl-[40px] rounded-tr-[40px] mt-[2px] justify-center relative flex-col items-center space-y-3 p-4 pt-20 pb-10">
              <button
                onClick={closeUpgrader}
                className="flex items-center justify-center h-[32px] w-[32px] rounded-full bg-[#383838] absolute right-6 top-4 text-center font-medium text-[16px]"
              >
                <IoClose size={20} className="text-[#9995a4]" />
              </button>

              <div className="w-full flex justify-center flex-col items-center">
                <img src='/dogs.webp' alt='reward' className='w-[70px]' />
                <h3 className="font-semibold text-center text-[20px] pt-2">Make a TON Transaction</h3>
                <p className="pb-6 text-primary text-[14px] px-4 text-center">
                  Make your first deposit and receive a 5 USDT bonus—it's a simple and rewarding way to get started!
                </p>

                <div className='w-full flex justify-center items-center space-x-2 pb-3'>
                  <div className="w-[45%] bg-cards text-[12px] rounded-[6px] p-2 text-primary flex items-center justify-center space-x-1 font-semibold text-center">
                    <span>Price:</span> <img src='ton.png' alt='TON' className='w-[12px] h-[12px]' /> <span>{price} TON</span>
                  </div>
                </div>
              </div>

              {wallets ? (
                <div className="w-full flex justify-center items-center flex-col space-y-2 pb-7">
                  <button
                    onClick={handleClick}
                    className={`${buttonDisabled ? 'bg-[#5A4420]' : 'bg-btn4'} text-[#000] w-full py-[18px] px-6 flex items-center justify-center rounded-[12px] font-semibold text-[17px]`}
                    disabled={buttonDisabled}
                  >
                    {buttonText}
                  </button>
                  {message && (
                    <p className='w-full text-center text-[13px]' style={{ color: messageColor, marginTop: "10px" }}>
                      {message}
                    </p>
                  )}
                </div>
              ) : (
                <TonConnectButton className="!w-full" />
              )}
            </div>
          </div>
        </div>
      )}

      <div className='w-full absolute top-[50px] flex justify-center z-50 pointer-events-none select-none'>
        {congrats ? (<img src='/congrats.gif' alt="congrats" className="w-[80%]" />) : (<></>)}
      </div>

      {showCongratsModal && (
        <div className="fixed inset-0 z-40 h-[100vh] bg-[#00000042] flex justify-center items-center backdrop-blur-[6px] px-4">
          <div className="w-full bg-modal relative rounded-[16px] flex flex-col justify-center p-8">
            {congratsMessage}
            <div className="w-full flex justify-center">
              <button
                onClick={() => setShowCongratsModal(false)}
                className="bg-btn4 w-full py-[16px] px-6 rounded-[12px] font-medium text-[16px]"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TonTask;
